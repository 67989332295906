import React from 'react';
import './estrutura.css';
import { Carousel } from 'react-bootstrap';

const Estrutura = () => {
  const comodidades = [
    {
      titulo: "Recepção",
      descricao: "Nossa recepção é bem aconchegante, garantindo conforto e acolhimento para os hóspedes desde a chegada.",
      imagens: [
        require("../../assets/image/estrutura/recepcao1.png"),
        require("../../assets/image/estrutura/recepcao2.png"),
        require("../../assets/image/estrutura/recepcao3.png"),
      ],
    },
    {
      titulo: "Sala de Reuniões e Descanso",
      descricao: "Disponibilizamos uma sala de reuniões e um espaço para descanso, proporcionando conforto e praticidade para nossos hóspedes.",
      imagens: [
        require("../../assets/image/estrutura/descanso1.png"),
        require("../../assets/image/estrutura/descanso2.png"),
        require("../../assets/image/estrutura/descanso3.png"),
      ],
    },
    {
      titulo: "Café da Manhã",
      descricao: "O café da manhã já está incluso em sua estadia e é servido em um ambiente agradável e acolhedor.",
      imagens: [
        require("../../assets/image/estrutura/lanchonete1.png"),
        require("../../assets/image/estrutura/lanchonete2.png"),
        require("../../assets/image/estrutura/lanchonete3.png"),
      ],
    },
    {
      titulo: "Garagem Coberta e Segura",
      descricao: "Oferecemos garagem coberta e segura para sua tranquilidade durante toda a sua estadia.",
      imagens: [
        require("../../assets/image/estrutura/evento1.png"),
        require("../../assets/image/estrutura/evento2.png"),
        require("../../assets/image/estrutura/evento3.png"),
      ],
    },
  ];

  return (
    <main id="main">
      <section className="service-details">
        <div className="container">
          <h2 className="text-center card-text pb-2">Contamos Com</h2>
          <div className="row">
            {comodidades.map((comodidade, index) => (
              <div key={index} className="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
                <div className="card">
                  <div className="card-img">
                    <Carousel>
                      {comodidade.imagens.map((imagem, imgIndex) => (
                        <Carousel.Item key={imgIndex}>
                          <img
                            className="d-block w-100"
                            src={imagem}
                            alt={`${comodidade.titulo} ${imgIndex + 1}`}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">{comodidade.titulo}</h5>
                    <p className="card-text">{comodidade.descricao}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Estrutura;
