import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom"; // Importa Link do react-router-dom
import logo from "../../assets/image/Home/hotelf.png";
import './header.css'
const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img className="imagemlogo" src={logo} alt="Hotel Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/sobre">Sobre</Nav.Link>
            <Nav.Link as={Link} to="/acomodações">Acomodações</Nav.Link>
            <Nav.Link as={Link} to="/localizacao">Localização</Nav.Link>
            <Nav.Link as={Link} to="/estrutura">Estrutura</Nav.Link>
            <Nav.Link as={Link} to="/Termos">Termos e Condições</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
